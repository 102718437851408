export function getCookie(name: any) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (const element of cookies) {
      const cookie = element.trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const getAuthHeader = (access_token: string) => {
  return { Authorization: `Bearer  ${access_token}` };
};

export const getAntiForgeryToken = () => {
  const token = document.cookie
    .split("; ")
    .find((row) => row.startsWith("XSRF-TOKEN"));
  return token ? decodeURIComponent(token.split("=")[1]) : null;
};

export const getNewProductCode = (
  brandCode?: string,
  productCode?: string,
  lang?: string
) => {
  if (productCode?.includes("de_") || productCode?.includes("us_")) {
    return productCode;
  } else {
    return lang?.includes("us") ? "us_" + productCode : "de_" + productCode;
  }
};
