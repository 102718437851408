import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalContext } from "../../context/modalContext";
import { BrandConstants, ScreenTypeDto } from "../../models";

export const SOLinkContent = (props: any) => {
  const {
    cal,
    product,
    getMeasurements,
    setStep,
    setReturnToScreen,
    target,
    showModal,
    saizEvents,
    brandCode,
  } = props;
  const { setModal } = useContext(ModalContext);
  const { t } = useTranslation("common");
  const [visible, setVisible] = useState(true);
  const handleScroll = () => {
    const target = document
      ?.querySelector(`div[data-e2e='carousel-similar-products']`)
      ?.getBoundingClientRect().top;

    if (window.innerWidth > 600 && target) {
      setVisible(target > 525);
    } else if (window.innerWidth < 600 && target) {
      setVisible(target > 525);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {product?.isLoaded && product?.product?.isActive && !showModal && (
        <div
          role="button"
          ref={(node) => {
            node?.style.setProperty("background", "#000000", "important");
            node?.style.setProperty("box-shadow", "none", "important");
            node?.style.setProperty(
              "top",
              brandCode === BrandConstants.SOLIVER ? "500px" : "350px",
              "important"
            );
            node?.style.setProperty("z-index", "10", "important");
            node?.style.setProperty(
              "display",
              visible ? "flex" : "none",
              "important"
            );
          }}
          onClick={() => {
            if (cal?.data?.data?.measuredSize) {
              if (!cal?.isLoaded) {
                getMeasurements();
              }
              setStep(ScreenTypeDto.Summary);
              setReturnToScreen(ScreenTypeDto.Summary);
              saizEvents(ScreenTypeDto.Summary);
            } else {
              setStep(ScreenTypeDto.ScreenOne);
              setReturnToScreen(ScreenTypeDto.ScreenOne);
              saizEvents(ScreenTypeDto.ScreenOne);
            }
            setModal(true);
          }}
          id="saiz-floating-button"
          className={`slide-in ${target ? "saiz-block-button" : "saiz-fixed"}`}
        >
          <div
            ref={(node) => {
              node?.style.setProperty("background", "#fff", "important");
            }}
          >
            {!cal?.isLoaded || cal?.data?.noSize ? (
              <>
                <span className="saiz-button-calculate">
                  {t("calculateSize")}
                </span>
                <span className="saiz-button-calculate-mobile">
                  {product?.product?.brandCode?.toLowerCase() === "bonita"
                    ? t("calculateSize")
                    : t("calculateSizeMobile")}
                </span>
              </>
            ) : (
              <>
                <span
                  className="saiz-best-size"
                  ref={(node) => {
                    node?.style.setProperty(
                      "background",
                      "#000000",
                      "important"
                    );
                  }}
                >
                  {cal?.data?.data?.measuredSize}
                </span>
                <span
                  className="saiz-best-size-text"
                  ref={(node) => {
                    node?.style.setProperty("background", "#fff", "important");
                  }}
                >
                  {" "}
                  {t("isYourBest")}
                </span>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
