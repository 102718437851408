import React, { Suspense } from "react";
import { createRoot, Root } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { defaultTheme } from "./themes/theme";
import { AuthLayout } from "./layout";
import ModalContextProvider from "./context/modalContext";
import II8n from "./i18n";
import { store, persistor } from "./redux/store/store";
import { renderWidgetParamsFromContainer } from "./utils/utils";
import { GlobalStyles } from "./themes/global";
import "./styles/style.scss";
import App from "./App";
import { cookiesListner } from "./redux/actions/cookieActions";
import { ThemeProvider } from "styled-components";
import { BrandConstants } from "./models";
import {
  trackWindowDataLayer,
  initializeTagManager,
  logPageView,
} from "./tracker";
class SaizWidget extends React.Component<
  any,
  {
    id: string;
    brandCode: string;
    lang: string;
    productCode: string;
    visitorId: string;
  }
> {
  googlTag = () => {
    II8n(this.state?.brandCode);
    delete theme.styles.global;
  };
  constructor(props: any) {
    super(props);
    let lang = props.lang;
    if (props.brandCode?.toUpperCase() === BrandConstants.ETERNA) {
      switch (props.lang?.toLowerCase()) {
        case "be":
          lang = "fr";
          break;
        case "dk":
        case "es":
        case "ie":
          lang = "en";
          break;
        default:
          lang = props.lang;
          break;
      }
    }
    this.state = {
      id: props.id,
      brandCode: props.brandCode?.toUpperCase(),
      productCode: props.productCode,
      lang: lang,
      visitorId:
        props.visitorId ?? localStorage.getItem("saiz_widget_visitorid"),
    };
    this.googlTag();
    cookiesListner(props.brandCode);
  }
  componentDidMount() {
    sessionStorage.setItem("saiz-widget-onload", "loaded");
    const name = "saiz-recommender";
    const last_version = sessionStorage.getItem(`${name}`);
    trackWindowDataLayer("saiz_Recommender_Initialization", {
      brandCode: this.state.brandCode,
      productCode: this.state.productCode,
      session_id: localStorage.getItem("saiz_session_id"),
      visitorId: this.state.visitorId,
      lang: this.state.lang,
      user_id: this.state.visitorId,
    });
    console.log("saiz loaded! 😝");
    if (last_version !== process.env.REACT_APP_VERSION) {
      console.log(
        `${name}`,
        `${process.env.REACT_APP_VERSION}`,
        "New Version Available ! 😝"
      );
      sessionStorage.setItem(`${name}`, `${process.env.REACT_APP_VERSION}`);
    }
    initializeTagManager(this.state?.brandCode);
    logPageView();
  }
  render() {
    return (
      <ThemeProvider theme={{ ...this.state }}>
        <GlobalStyles />
        <AuthLayout {...this.state}>
          <ModalContextProvider {...this.state}>
            <App {...this.state} />
          </ModalContextProvider>
        </AuthLayout>
      </ThemeProvider>
    );
  }
}
export default SaizWidget;

const AppWithCallbackAfterRender = (props: any) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={null}>
          <ChakraProvider
            theme={defaultTheme}
            resetCSS={false}
            cssVarsRoot="#saiz-modal-container"
            disableGlobalStyle={true}
            resetScope="#saiz-modal-container"
            key={"saiz"}
          >
            <SaizWidget id="saiz-widget-container" {...props} />
          </ChakraProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  );
};
let root: Root | null = null;

const setContainer = (container: any) => {
  const { productCode, brandCode, lang, visitorId } =
    renderWidgetParamsFromContainer(container);
  let pCode = productCode;
  pCode = pCode.replaceAll("#", "-");
  if (
    brandCode?.toUpperCase() === BrandConstants.BOGNER ||
    brandCode?.toUpperCase() === BrandConstants.FIREICE
  ) {
    if (!root) root = createRoot(container);
  } else root = createRoot(container);
  if (!productCode) {
    const urlParts = window.location.href.split("/");
    const urlSegment = urlParts.pop();
    const code = urlSegment ?? "";
    pCode = code.split("-").slice(0, 3).join("-");
  }
  localStorage.setItem("saiz-widget-productcode", pCode);
  root.render(
    <AppWithCallbackAfterRender
      productCode={productCode}
      brandCode={brandCode?.toUpperCase()}
      lang={lang}
      visitorId={visitorId}
    />
  );
};

const pressTrigger = (productCode: string) => {
  localStorage.setItem("saiz-widget-productcode", productCode);
  window.dispatchEvent(new Event("saiz-widget-storage"));
};
const eventHandlers = () => {
  window.addEventListener("saiz-widget-initializer", (event: any) => {
    if (
      event?.detail?.productcode !== "" &&
      localStorage.getItem("saiz-widget-productcode") !==
        event?.detail?.productcode
    ) {
      pressTrigger(event?.detail?.productcode);
    }
  });

  window.addEventListener("saiz-widget-loader", () => {
    const container = document.getElementById("saiz-widget-container");
    if (container) {
      setContainer(container);
    }
  });
  window.addEventListener("saiz-widget-pushState", (event: any) => {
    tomTailor(event);
  });
  // Listen for the popstate event (when the user navigates back)
  window.addEventListener("popstate", () => {
    window.dispatchEvent(new Event("saiz-widget-loader"));
    console.log("saiz loaded! popstate 😝");
  });
  window.addEventListener("hashchange", () => {
    window.dispatchEvent(new Event("saiz-widget-loader"));
    console.log("hashchange saiz loaded! 😝");
  });
  window.addEventListener("load", () => {
    window.dispatchEvent(new Event("saiz-widget-loader"));
    console.log("load saiz loaded! 😝");
  });
};
const init = () => {
  sessionStorage.setItem("saiz-widget-onload", "loading...");
  const container = document.getElementById("saiz-widget-container");
  if (container) {
    setContainer(container);
  } else {
    window.dispatchEvent(new Event("saiz-widget-loader"));
  }
};

const watchHistoryEvents = (state: any) => {
  const { pushState, replaceState } = window.history;
  window.history.pushState = function (...args) {
    pushState.apply(window.history, args);
    window.dispatchEvent(
      new CustomEvent("saiz-widget-pushState", {
        detail: { trigger: "pushState", state },
      })
    );
  };
  window.history.replaceState = function (...args) {
    replaceState.apply(window.history, args);
    window.dispatchEvent(
      new CustomEvent("saiz-widget-pushState", {
        detail: { trigger: "replaceState", state },
      })
    );
  };
};

const tomTailor = (event: any) => {
  const urlParts = window.location.href.split("/");
  const urlSegment = urlParts.pop();
  const codeParts = urlSegment?.split("-");
  let productCode = codeParts?.pop() ?? "";
  productCode = productCode?.replaceAll(" ", "_");
  const pattern = "^[^a-zA-Z][a-zA-Z0-9_]*$";
  const regex = new RegExp(pattern);
  if (
    productCode !== "" &&
    regex.test(productCode) &&
    localStorage.getItem("saiz-widget-productcode") !== productCode
  ) {
    if (productCode !== localStorage.getItem("saiz-widget-productcode")) {
      localStorage.setItem("saiz-widget-productcode", productCode);
      window.dispatchEvent(new Event("saiz-widget-storage"));
    }
  }
};
window.addEventListener("saiz-widget-reload", init);
init();
eventHandlers();
reportWebVitals();
