import { Dispatch } from "redux";
import { CookieConstants } from "../constants";
import { readCookie } from "../../utils/utils";
import { BrandConstants } from "../../models";
export const CookieActions = { getCookie };

interface CookieConfig {
  brandCode: string;
  elements: ElementConfig[];
}

interface ElementConfig {
  querySelector: string;
}

const Config: CookieConfig[] = [
  {
    brandCode: BrandConstants.BONITA,
    elements: [
      {
        querySelector: `button[data-testid="uc-deny-all-button"]`,
      },
      {
        querySelector: `button[data-testid="uc-accept-all-button"]`,
      },
    ],
  },

  {
    brandCode: BrandConstants.TOMTAILOR,
    elements: [
      {
        querySelector: `button[data-testid="uc-save-button"]`,
      },
      {
        querySelector: `button[data-testid="uc-accept-all-button"]`,
      },
    ],
  },
  {
    brandCode: BrandConstants.JACKWOLFSKIN,
    elements: [
      {
        querySelector: `button[data-testid="uc-save-button"]`,
      },
      {
        querySelector: `button[data-testid="uc-accept-all-button"]`,
      },
    ],
  },
  {
    brandCode: BrandConstants.ETERNA,
    elements: [
      {
        querySelector: `button[data-testid="uc-save-button"]`,
      },
      {
        querySelector: `button[data-testid="uc-accept-all-button"]`,
      },
    ],
  },
  {
    brandCode: BrandConstants.TRIGEMA,
    elements: [
      {
        querySelector: `button[id="CybotCookiebotDialogBodyButtonDecline"]`,
      },
      {
        querySelector: `button[id="CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll"]`,
      },
    ],
  },
  {
    brandCode: BrandConstants.TCHIBO,
    elements: [
      {
        querySelector: `button[class="save-preference-btn-handler onetrust-close-btn-handler"]`,
      },
      {
        querySelector: `button[id="onetrust-accept-btn-handler"]`,
      },
    ],
  },
  {
    brandCode: BrandConstants.BOGNER,
    elements: [
      {
        querySelector: `button[id="onetrust-accept-btn-handler"]`,
      }
    ],
  },
  {
    brandCode: BrandConstants.FIREICE,
    elements: [
      {
        querySelector: `button[id="onetrust-accept-btn-handler"]`,
      },
     
    ],
  },
];


function getCookie(brandCode: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(request());
    dispatch(success(readCookie(brandCode)));
    function request() {
      return { type: CookieConstants.GET_WIDGET_COOKIE_REQUEST };
    }
    function success(isActive: boolean) {
      return { type: CookieConstants.GET_WIDGET_COOKIE_SUCCESS, isActive };
    }
  };
}

export const cookiesListner = (brandCode: string) => {
  try {
    const brand = Config.find((y) => y.brandCode === brandCode);
    if (brand) {
      brand.elements.map((element: ElementConfig) => {
        if (brandCode === BrandConstants.TOMTAILOR)
          readShadowRoot(element, brandCode);
        else addCookieEventListner(element, brandCode);
      });
    }
  } catch (err) {
    console.error(err);
  }
};

const readShadowRoot = (element: ElementConfig, brandCode: string) => {
  const container = document.getElementById("usercentrics-root");
  // Access the first shadow root
  const firstShadowRoot = container?.shadowRoot;
  // Access the second shadow root
  const secondShadowRoot = firstShadowRoot?.querySelector(
    element.querySelector
  );
  addEventListner(secondShadowRoot, brandCode);
};

const addCookieEventListner = (element: ElementConfig, brandCode: string) => {
  const button = document.querySelector(element.querySelector);
  addEventListner(button, brandCode);
};

const addEventListner = (button: any, brandCode: string) => {
  button?.addEventListener("click", () => {
    const result = readCookie(brandCode);
    console.log(brandCode);
    window.dispatchEvent(new Event("load"));
    return result;
  });
};
